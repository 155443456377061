<template>
    <template v-if="basic">
        <div :class="e.size">
            <div class="form-floating" :class="[ type == 'file' ? 'mb-1' : 'mb-3',  e.opaque ? 'opaque' : '']">
            <template v-if="type == 'select'">
                <select class="form-select" v-bind="$attrs" :id="e.controlId" @change="setValue($event)" :disabled="e.disabled">
                    <slot :selected="getValue()">
                        <option :value="r[bindData]" v-for="r in e.list" :selected="C.Str(r[bindData]) == getValue()">{{r[bindText]}}</option>
                    </slot>
                </select>
            </template>
            <template v-else-if="type == 'email'">
                <input type="email" class="form-control" v-bind="$attrs" :id="e.controlId" :value="getValue()" @input="setValue($event)" :disabled="e.disabled" />
            </template>
            <template v-else-if="type == 'password'">
                <input :type="e.showPassword ? 'text': 'password'" class="form-control" v-bind="$attrs" :id="e.controlId" :value="getValue()" @input="setValue($event)" :disabled="e.disabled" />
                <span v-show="!e.disabled" class="input-btn btn" @click="e.showPassword = !e.showPassword"><i class="fa" :class="e.showPassword ? 'fa-eye-slash' : 'fa-eye'" /></span>
            </template>
            <template v-else-if="type == 'tc'">
                <input type="text" class="form-control" v-bind="$attrs" :id="e.controlId" :value="getValue()" @input="setValue($event)" :disabled="e.disabled" maxlength="11" />
            </template>
            <template v-else-if="type == 'tel'">
                <input type="tel" class="form-control" v-bind="$attrs" :id="e.controlId" :value="getValue()" @input="setValue($event)" :disabled="e.disabled" />
            </template>
            <template v-else-if="type == 'date'">
                <template v-if="e.disabled">
                    <div class="form-control label" :id="e.controlId" :value="D.DateToString(getValue())">{{ D.DateToString(getValue()) }}</div>
                </template>
                <template v-else>
                    <!--<input type="date" class="form-control" v-bind="$attrs" :id="e.controlId" :value="getValue()" @input="setValue($event)" :disabled="e.disabled" />-->
                    <Datepicker class="form-control" :uid="e.controlId" :modelValue="getValue()" @update:modelValue="setValue($event)" :disabled="e.disabled" :enable-time-picker="false" locale="tr" position="left" select-text="Seç" cancel-text="İptal" :clearable="false" auto-apply format="dd.MM.yyyy"></Datepicker>
                </template>
            </template>
            <template v-else-if="type == 'time'">
                <template v-if="e.disabled">
                    <div class="form-control label" :id="e.controlId" :value="D.TimeToString(getValue(), true)">{{ D.TimeToString(getValue()) }}</div>
                </template>
                <template v-else>
                    <!--<input type="time" class="form-control" v-bind="$attrs" :id="e.controlId" :value="getValue()" @input="setValue($event)" :disabled="e.disabled" />-->
                    <Datepicker class="form-control" :uid="e.controlId" :modelValue="getValue()" time-picker @update:modelValue="setValue($event)" :disabled="e.disabled" locale="tr" enable-seconds position="left" select-text="Seç" cancel-text="İptal" :clearable="false" auto-apply format="HH:mm:ss"></Datepicker>
                </template>
            </template>
            <template v-else-if="type == 'datetime'">
                <template v-if="e.disabled">
                    <div class="form-control label" :id="e.controlId" :value="D.DateTimeToString(getValue())">{{ D.DateTimeToString(getValue()) }}</div>
                </template>
                <template v-else>
                    <Datepicker class="form-control" :uid="e.controlId" :modelValue="getValue()" @update:modelValue="setValue($event)" :disabled="e.disabled" locale="tr"  position="left" select-text="Seç" cancel-text="İptal" :clearable="false" format="dd.MM.yyyy HH:mm"></Datepicker>
                    <!--<input type="datetime-local" class="form-control" v-bind="$attrs" :id="e.controlId" :value="getValue()" @input="setValue($event)" />-->
                </template>
            </template>
            <template v-else-if="type == 'decimal'">
                <input type="text" class="form-control text-end" v-bind="$attrs" :id="e.controlId" :value="getValue()" @blur="setValue($event)" min="0.0000" max="999999.0000" step="0.0001" :disabled="e.disabled" />
            </template>
            <template v-else-if="type == 'money'">
                <input type="text" class="form-control text-end" v-bind="$attrs" :id="e.controlId" :value="getValue()" @blur="setValue($event)" min="0.00" max="999999.00" step="0.01" :disabled="e.disabled" />
                <span class="input-btn left"><i class="fa fa-try" /></span>
            </template>
            <template v-else-if="type == 'number'">
                <input type="number" class="form-control text-end" v-bind="$attrs" :id="e.controlId" :value="getValue()" @input="setValue($event)" min="0" max="999999" step="1" :disabled="e.disabled" />
            </template>
            <template v-else-if="type == 'color'">
                <input type="color" class="form-control" v-bind="$attrs" :id="e.controlId" :value="getValue()" @input="setValue($event)" :disabled="e.disabled" />
            </template>
            <template v-else-if="type == 'range'">
                <input type="range" class="form-control form-range" v-bind="$attrs" :id="e.controlId" :value="getValue()" @input="setValue($event)" :disabled="e.disabled" />
            </template>
            <template v-else-if="type == 'checkbox'">
                <div class="form-control" v-bind="$attrs">
                    <div class="form-check" v-if="!C.IsEmptyArray(e.list)">
                        <input class="form-check-input" type="checkbox" :name="e.controlId" :id="e.controlId + '_0'" value="true" @change="setValue($event)" :checked="C.Bool(getValue())" />
                    </div>
                    <div class="form-check" v-else :class="{ 'form-check-inline' : C.Bool(inline) }" v-for="(r, index) in e.list">
                        <input class="form-check-input" type="checkbox" :name="e.controlId" :id="e.controlId + '_' + index" :value="r[bindData]" @change="setValue($event)" :checked="Text.In(r[bindData], getValue())" />
                        <label class="form-check-label" :for="e.controlId + '_' + index">{{r[bindText]}}</label>
                    </div>
                </div>
            </template>
            <template v-else-if="type == 'radio'">
                <div class="form-control" v-bind="$attrs">
                    <div class="form-check" :class="{ 'form-check-inline' : C.Bool(inline) }" v-for="(r, index) in e.list">
                        <input class="form-check-input" type="radio" :name="e.controlId" :id="e.controlId + '_' + index" :value="r[bindData]" @change="setValue($event)" :checked="C.Str(r[bindData]) == C.Str(getValue())" />
                        <label class="form-check-label" :for="e.controlId + '_' + index">{{r[bindText]}}</label>
                    </div>
                </div>
            </template>
            <template v-else-if="type == 'switch'">
                <div class="form-control" v-bind="$attrs">
                    <div class="form-check form-switch" v-if="!C.IsEmptyArray(e.list)">
                        <input class="form-check-input" type="checkbox" :name="e.controlId" :id="e.controlId + '_0'" value="true" @change="setValue($event)" :checked="C.Bool(getValue())" />
                    </div>
                    <div class="form-check form-switch" v-else v-for="(r, index) in e.list">
                        <input class="form-check-input" type="checkbox" :name="e.controlId" :id="e.controlId + '_' + index" :value="r[bindData]" @change="setValue($event)" :checked="Text.In(r[bindData], getValue())" />
                        <label class="form-check-label" :for="e.controlId + '_' + index">{{r[bindText]}}</label>
                    </div>
                </div>
            </template>
            <template v-else-if="type == 'file'">
                <input type="file" class="form-control" v-bind="$attrs" :id="e.controlId" :accept="C.Str(accept, 'image/*,.pdf,.doc,.docx,.xls,.xlsx')" placeholder="Dosya seçiniz..." @change="setValue($event)" :disabled="e.disabled" />
                <!--<div class="uploads-wrapper form-control">
                    <div class="uploads-header">
                        <div class="title">
                            <span><i class="add-file"></i>Dosya Ekle</span>

                        </div>
                        <div class="detail">Bir seferde en fazla 10 dosya ekleyin, Toplam dosya boyutu 25 MB'ı geçemez.</div>
                    </div>
                    <div class="uploads-body">
                        <div class="file-upload-wrapper">
                            <input type="file" class="file-upload-field" v-bind="$attrs" :id="e.controlId" :accept="C.Str(accept, 'image/*,.pdf,.doc,.docx,.xls,.xlsx')" placeholder="Dosya seçiniz..." @change="setValue($event)" :disabled="e.disabled" />
                        </div>
                    </div>
                    <div class="uploads-footer">-->
                        <!--<button class="btn btn-primary">Dosya Ekle</button>
                        <button class="btn btn-primary">İptal</button>-->
                    <!--</div>
                </div>-->
            </template>
            <template v-else-if="type == 'image'">
                <div :disabled="e.disabled" class="form-control-upload mb-3" @click="imageClick" @dragover="imageDragOver" @dragleave="imageDragLeave" @drop="imageDrop">
                    <div class="item" v-if="C.Str(getValue(), 'null') != 'null'">
                        <img :src="Web.Server(getValue())" :style="{'min-height': minHeight, 'max-height': maxHeight}" />
                        <a v-if="!e.disabled" href="javascript:void(0);" @click.stop="imageRemove" class="btn btn-danger btn-sm"><i class="fa fa-trash"></i></a>
                    </div>
                    <div class="text-center p-3" v-if="C.Str(getValue(), 'null') == 'null' && !e.disabled">
                        <i class="fa fa-upload" style="font-size:2rem"></i>
                        <div>Drop files here or click to upload.</div>
                    </div>
                </div>
                <input type="file" class="form-control" v-bind="$attrs" :id="e.controlId" :accept="C.Str(accept, 'image/*')" @change="setValue($event)" style="display:none;" />
            </template>
            <template v-else-if="type == 'textarea'">
                <textarea rows="3" class="form-control" v-bind="$attrs" :id="e.controlId" :value="getValue()" @input="setValue($event)" :disabled="e.disabled"></textarea>
            </template>
            <template v-else-if="type == 'label'">
                <div class="form-control label">{{ getValue() }}</div>
            </template>
            <template v-else>
                <input type="text" class="form-control" v-bind="$attrs" :id="e.controlId" :list="e.controlId + '_list'" :value="getValue()" @input="setValue($event)" />
                <datalist :id="e.controlId + '_list'" v-if="e.list.length > 0">
                    <option :value="r" v-for="r in e.list" />
                </datalist>
            </template>
            <label :for="e.controlId" v-if="e.title != ''">{{ e.title }}</label>
            </div>
        </div>
    </template>
    <template v-else>
        <div class="form-group" :class="[icon ? 'input-group-icon' : '' ]">
            <!--<div class="form-floating" :class="[ type == 'file' ? 'mb-1' : 'mb-3',  e.opaque ? 'opaque' : '']"> -->
            <!--<label :for="e.controlId" v-if="e.title != ''">{{ e.title }}</label>-->
            <div class="input-text" v-if="e.title != ''">{{ e.title }}</div>
            <div class="input-icon" v-if="icon != ''"><i :class="[icon]"></i></div>
            <!--</div>-->
            <template v-if="type == 'select'">
                <select class="form-select" v-bind="$attrs" :id="e.controlId" @change="setValue($event)" :disabled="e.disabled">
                    <slot :selected="getValue()">
                        <option :value="r[bindData]" v-for="r in e.list" :selected="C.Str(r[bindData]) == getValue()">{{r[bindText]}}</option>
                    </slot>
                </select>
            </template>
            <template v-else-if="type == 'email'">
                <input type="email" class="form-control" v-bind="$attrs" :id="e.controlId" :value="getValue()" @input="setValue($event)" :disabled="e.disabled" />
            </template>
            <template v-else-if="type == 'password'">
                <input :type="e.showPassword ? 'text': 'password'" class="form-control" v-bind="$attrs" :id="e.controlId" :value="getValue()" @input="setValue($event)" :disabled="e.disabled" />
                <span v-show="!e.disabled" class="input-btn btn" @click="e.showPassword = !e.showPassword"><i class="fa" :class="e.showPassword ? 'fa-eye-slash' : 'fa-eye'" /></span>
            </template>
            <template v-else-if="type == 'tc'">
                <input type="text" class="form-control" v-bind="$attrs" :id="e.controlId" :value="getValue()" @input="setValue($event)" :disabled="e.disabled" maxlength="11" />
            </template>
            <template v-else-if="type == 'tel'">
                <input type="tel" class="form-control" v-bind="$attrs" :id="e.controlId" :value="getValue()" @input="setValue($event)" :disabled="e.disabled" />
            </template>
            <template v-else-if="type == 'date'">
                <template v-if="e.disabled">
                    <div class="form-control label" :id="e.controlId" :value="D.DateToString(getValue())">{{ D.DateToString(getValue()) }}</div>
                </template>
                <template v-else>
                    <!--<input type="date" class="form-control" v-bind="$attrs" :id="e.controlId" :value="getValue()" @input="setValue($event)" :disabled="e.disabled" />-->
                    <Datepicker class="form-control" :uid="e.controlId" v-bind="$attrs" :modelValue="getValue()" @update:modelValue="setValue($event)" :disabled="e.disabled" :enable-time-picker="false" locale="tr" position="left" select-text="Seç" cancel-text="İptal" :clearable="false" format="dd.MM.yyyy"></Datepicker>
                </template>
            </template>
            <template v-else-if="type == 'time'">
                <template v-if="e.disabled">
                    <div class="form-control label" :id="e.controlId" :value="D.TimeToString(getValue(), true)">{{ D.TimeToString(getValue()) }}</div>
                </template>
                <template v-else>
                    <!--<input type="time" class="form-control" :id="e.controlId" :value="getValue()" @input="setValue($event)" :disabled="e.disabled" />-->
                    <Datepicker class="form-control" :uid="e.controlId" v-bind="$attrs" :modelValue="getValue()" time-picker @update:modelValue="setValue($event)" :disabled="e.disabled" locale="tr" enable-seconds position="left" select-text="Seç" cancel-text="İptal" :clearable="false" auto-apply format="HH:mm:ss"></Datepicker>
                </template>
            </template>
            <template v-else-if="type == 'datetime'">
                <template v-if="e.disabled">
                    <div class="form-control label" :id="e.controlId" :value="D.DateTimeToString(getValue())">{{ D.DateTimeToString(getValue()) }}</div>
                </template>
                <template v-else>
                    <Datepicker class="form-control" :uid="e.controlId" v-bind="$attrs" :modelValue="getValue()" @update:modelValue="setValue($event)" :disabled="e.disabled" locale="tr" position="left" select-text="Seç" cancel-text="İptal" :clearable="false" format="dd.MM.yyyy HH:mm"></Datepicker>
                    <!--<input type="datetime-local" class="form-control" v-bind="$attrs" :id="e.controlId" :value="getValue()" @input="setValue($event)" />-->
                </template>
            </template>
            <template v-else-if="type == 'decimal'">
                <input type="text" class="form-control text-end" v-bind="$attrs" :id="e.controlId" :value="getValue()" @blur="setValue($event)" min="0.0000" max="999999.0000" step="0.0001" :disabled="e.disabled" />
            </template>
            <template v-else-if="type == 'money'">
                <input type="text" class="form-control text-end" v-bind="$attrs" :id="e.controlId" :value="getValue()" @blur="setValue($event)" min="0.00" max="999999.00" step="0.01" :disabled="e.disabled" />
                <span class="input-btn left"><i class="fa fa-try" /></span>
            </template>
            <template v-else-if="type == 'number'">
                <input type="number" class="form-control text-end" v-bind="$attrs" :id="e.controlId" :value="getValue()" @input="setValue($event)" min="0" max="999999" step="1" :disabled="e.disabled" />
            </template>
            <template v-else-if="type == 'color'">
                <input type="color" class="form-control" v-bind="$attrs" :id="e.controlId" :value="getValue()" @input="setValue($event)" :disabled="e.disabled" />
            </template>
            <template v-else-if="type == 'range'">
                <input type="range" class="form-control form-range" v-bind="$attrs" :id="e.controlId" :value="getValue()" @input="setValue($event)" :disabled="e.disabled" />
            </template>
            <template v-else-if="type == 'checkbox'">
                <div class="form-control" v-bind="$attrs">
                    <div class="form-check" v-if="!C.IsEmptyArray(e.list)">
                        <input class="form-check-input" type="checkbox" :name="e.controlId" :id="e.controlId + '_0'" value="true" @change="setValue($event)" :checked="C.Bool(getValue())" />
                    </div>
                    <div class="form-check" v-else :class="{ 'form-check-inline' : C.Bool(inline) }" v-for="(r, index) in e.list">
                        <input class="form-check-input" type="checkbox" :name="e.controlId" :id="e.controlId + '_' + index" :value="r[bindData]" @change="setValue($event)" :checked="Text.In(r[bindData], getValue())" />
                        <label class="form-check-label" :for="e.controlId + '_' + index">{{r[bindText]}}</label>
                    </div>
                </div>
            </template>
            <template v-else-if="type == 'radio'">
                <div class="form-control" v-bind="$attrs">
                    <div class="form-check" :class="{ 'form-check-inline' : C.Bool(inline) }" v-for="(r, index) in e.list">
                        <input class="form-check-input" type="radio" :name="e.controlId" :id="e.controlId + '_' + index" :value="r[bindData]" @change="setValue($event)" :checked="C.Str(r[bindData]) == C.Str(getValue())" />
                        <label class="form-check-label" :for="e.controlId + '_' + index">{{r[bindText]}}</label>
                    </div>
                </div>
            </template>
            <template v-else-if="type == 'switch'">
                <div class="form-control" v-bind="$attrs">
                    <div class="form-check form-switch" v-if="!C.IsEmptyArray(e.list)">
                        <input class="form-check-input" type="checkbox" :name="e.controlId" :id="e.controlId + '_0'" value="true" @change="setValue($event)" :checked="C.Bool(getValue())" />
                    </div>
                    <div class="form-check form-switch" v-else v-for="(r, index) in e.list">
                        <input class="form-check-input" type="checkbox" :name="e.controlId" :id="e.controlId + '_' + index" :value="r[bindData]" @change="setValue($event)" :checked="Text.In(r[bindData], getValue())" />
                        <label class="form-check-label" :for="e.controlId + '_' + index">{{r[bindText]}}</label>
                    </div>
                </div>
            </template>
            <template v-else-if="type == 'file'">
                <input type="file" class="form-control" v-bind="$attrs" :id="e.controlId" :accept="C.Str(accept, 'image/*,.pdf,.doc,.docx,.xls,.xlsx')" placeholder="Dosya seçiniz..." @change="setValue($event)" :disabled="e.disabled" />
            </template>
            <template v-else-if="type == 'image'">
                <div :disabled="e.disabled" class="form-control-upload mb-3" @click="imageClick" @dragover="imageDragOver" @dragleave="imageDragLeave" @drop="imageDrop">
                    <div class="item" v-if="C.Str(getValue(), 'null') != 'null'">
                        <img :src="Web.Server(getValue())" :style="{'min-height': minHeight, 'max-height': maxHeight}" />
                        <a v-if="!e.disabled" href="javascript:void(0);" @click.stop="imageRemove" class="btn btn-danger btn-sm"><i class="fa fa-trash"></i></a>
                    </div>
                    <div class="text-center p-3" v-if="C.Str(getValue(), 'null') == 'null' && !e.disabled">
                        <i class="fa fa-upload" style="font-size:2rem"></i>
                        <div>Drop files here or click to upload.</div>
                    </div>
                </div>
                <input type="file" class="form-control" v-bind="$attrs" :id="e.controlId" :accept="C.Str(accept, 'image/*')" @change="setValue($event)" style="display:none;" />
            </template>
            <template v-else-if="type == 'textarea'">
                <textarea rows="3" class="form-control" v-bind="$attrs" :id="e.controlId" :value="getValue()" @input="setValue($event)" :disabled="e.disabled"></textarea>
            </template>
            <template v-else-if="type == 'label'">
                <div class="form-control label">{{ getValue() }}</div>
            </template>
            <template v-else>
                <input type="text" class="form-control" v-bind="$attrs" :id="e.controlId" :list="e.controlId + '_list'" :value="getValue()" @input="setValue($event)" />
                <datalist :id="e.controlId + '_list'" v-if="e.list.length > 0">
                    <option :value="r" v-for="r in e.list" />
                </datalist>
            </template>
        </div>
    </template> 
    
</template>
<script>
    import { C, D } from '@/helpers/global';
    import { Web } from '@/helpers/web'; 

    let _uid = 1;
    export default {
        name: "Control",
        inheritAttrs: false,
        components: {
        },
        props: {
            modelValue: {
            },
            title: {
                type: String,
                required: true,
            },
            type: {
                type: String,
                required: true,
            },
            list: {
                type: Array,
                default: [],
            },
            size: {
                type: String,
                default: 'col-lg-12'
            },
            bindData: {
                type: String,
                default: 'Id'
            },
            bindText: {
                type: String,
                default: 'Adi'
            },
            inline: {
                type: Boolean,
                default: false
            },
            accept: {
            },
            minHeight: {
                default: '100px'
            },
            maxHeight: {
                default: '100px'
            },
            disabled: {
                type: Boolean,
                default: false
            },
            opaque: {
                type: Boolean,
                default: false
            },
            icon: {
                type: Boolean,
                default: false
            },
            basic: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                e: {
                    controlId: null,
                    title: this.title,
                    value: this.modelValue,
                    list: this.list,
                    size: this.size,
                    disabled: this.disabled,
                    opaque: this.opaque,
                    editor: this.editor,
                    showPassword: false,
                },
            };
        },
        emits: ['update:modelValue'],
        watch: {
            title: function (n) {
                this.e.title = n;
            },
            modelValue: function (n) {
                this.e.value = n;
            },
            list: function (n) {
                this.e.list = n;
            },
            size: function (n) {
                this.e.size = n;
            },
            disabled: function (n) {
                this.e.disabled = n;
            },
            opaque: function (n) {
                this.e.opaque = n;
            },
            editor: function (n) {
                this.e.editor = n;
            }
        },
        methods: {
            getValue() {
                switch (this.type) {
                    case 'editor':
                        return C.Str(this.e.value);
                    case 'file':
                        return C.Str(this.e.value);
                    case 'date':
                        return C.Date(this.e.value);
                    case 'time':
                        return C.Date(this.e.value);
                    case 'datetime':
                        return C.Date(this.e.value);
                    case 'money':
                        return C.Money(this.e.value, 2);
                    case 'decimal':
                        return C.Money(this.e.value, 4);
                    default:
                        return C.Str(this.e.value);
                }
            },
            setValue(event) {
                var _this = this;
                switch (this.type) {
                    case 'editor':
                        this.$emit('update:modelValue', event);
                        break;
                    case 'file':
                    case 'image':
                        Web.GetFileBase64(event.target, 0, function (status, base64) {
                            if (status) {
                                //var mimeType = Web.GetMimeType(base64);
                                //if (mimeType) {
                                //    var t = Web.FindExtension(mimeType);
                                //    if (t) {
                                //        if (_this.type != t.Group) {
                                //            // Geçersiz format
                                //        }
                                //    }
                                //}

                                //_this.fileName = event.target.files[0].name;
                                _this.$emit('update:modelValue', base64);
                            }
                            else { _this.$emit('update:modelValue', null); }
                        });
                        break;
                    case 'checkbox':
                        var l = document.getElementsByName(event.target.name);
                        var p = [];
                        for (var i = 0; i < l.length; i++) {
                            if (l[i].checked) {
                                p.push(l[i].value);
                            }
                        }
                        this.e.value = p.toString();
                        this.$emit('update:modelValue', this.e.value);
                        break;
                    case 'switch':
                        var l = document.getElementsByName(event.target.name);
                        var p = [];
                        for (var i = 0; i < l.length; i++) {
                            if (l[i].checked) {
                                p.push(l[i].value);
                            }
                        }
                        this.e.value = p.toString();
                        this.$emit('update:modelValue', this.e.value);
                        break;
                    case 'auto-select':
                        this.e.value = event;
                        this.$emit('update:modelValue', this.e.value);
                        break;
                    case 'money':
                        this.e.value = event.target.value;
                        this.$emit('update:modelValue', this.e.value);
                        break;
                    case 'decimal':
                        this.e.value = C.Dec(event.target.value);
                        this.$emit('update:modelValue', this.e.value);
                        break;
                    case 'date':
                        this.e.value = event;
                        this.$emit('update:modelValue', this.e.value);
                        break;
                    case 'time':
                        this.e.value = event;
                        this.$emit('update:modelValue', this.e.value);
                        break;
                    case 'datetime':
                        this.e.value = event;
                        this.$emit('update:modelValue', this.e.value);
                        break;
                    default:
                        this.e.value = C.Str(event.target.value);
                        this.$emit('update:modelValue', this.e.value);
                        break;
                }
            },
            imageClick() {
                if (this.e.disabled) { return; }
                document.querySelector('#' + this.e.controlId).click();
            },
            imageRemove(event) {
                document.querySelector('#' + this.e.controlId).value = '';
                this.e.value = 'null';
                this.$emit('update:modelValue', this.e.value);
            },
            imageDragOver() {
                if (this.e.disabled) { return; }
                event.preventDefault();
                if (!event.currentTarget.classList.contains('drag')) {
                    event.currentTarget.classList.add('drag');
                }
            },
            imageDragLeave() {
                if (this.e.disabled) { return; }
                event.currentTarget.classList.remove('drag');
            },
            imageDrop(event) {
                var _this = this;
                if (this.e.disabled) { return; }
                event.preventDefault();
                event.currentTarget.classList.remove('drag');

                if (event.dataTransfer.files.length > 0) {
                    var file = event.dataTransfer.files[0];
                    if (!file.type.match(/image.*/)) { return; }

                    var reader = new FileReader();
                    reader.onload = function () {
                        _this.e.value = reader.result;
                        _this.$emit('update:modelValue', _this.e.value);
                    };
                    reader.readAsDataURL(file);
                }
                else {
                    var src = event.dataTransfer.getData('url');
                    var o = new Image();
                    o.onload = function () {
                        var c = document.createElement("canvas");
                        c.width = o.width;
                        c.height = o.height;
                        var ctx = c.getContext("2d");
                        ctx.drawImage(o, 0, 0);

                        _this.e.value = c.toDataURL();
                        _this.$emit('update:modelValue', _this.e.value);
                    }
                    o.src = src;
                }
            },
        },
        mounted() {
            this.e.controlId = 'c_' + _uid;
            _uid++;
            //var control = document.querySelector('#' + this.e.controlId);
        }
    };
</script>