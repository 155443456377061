import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import VueSweetalert2 from "vue-sweetalert2";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import Datepicker from '@vuepic/vue-datepicker';

import { Enums, Links, Static } from "./helpers/enums";
import { appData, C, Text, D, J, Rnd } from "./helpers/global";
import { AppFunction } from "./helpers/app";

import Control from "./components/Control.vue";
import ControlFilter from "./components/ControlFilter.vue";
import CurrencyUnit from "./components/CurrencyUnit.vue";
import SmartTable from "./components/SmartTable.vue";
import ExportButtons from "./components/ExportButtons.vue";
import SmartModal from "./components/SmartModal.vue";
import {
  SupportType,
  MethodType,
  Web,
  Msg,
  Popup,
  Wait,
  Ajax,
  Exporter,
  Animation,
} from "./helpers/web";

import "bootstrap";
import "sweetalert2/dist/sweetalert2.min.css";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import 'bootstrap-icons/font/bootstrap-icons.css'; // calendar
import '@vuepic/vue-datepicker/dist/main.css';
import "./assets/scss/app.scss";

var app = createApp(App);
app.use(router);
app.use(VueAxios, axios);
app.use(VueSweetalert2);
app.use(PerfectScrollbar);
app.component('Datepicker', Datepicker);

app.config.globalProperties.Enums = Enums;
app.config.globalProperties.Links = Links;
app.config.globalProperties.Static = Static;

app.config.globalProperties.appData = appData;
app.config.globalProperties.C = C;
//app.config.globalProperties.Lang = Lang;
app.config.globalProperties.Text = Text;
app.config.globalProperties.D = D;
app.config.globalProperties.J = J;
app.config.globalProperties.Rnd = Rnd;
app.config.globalProperties.SupportType = SupportType;
app.config.globalProperties.MethodType = MethodType;
//app.config.globalProperties.AjaxPromise = AjaxPromise;
app.config.globalProperties.Web = Web;
app.config.globalProperties.Msg = Msg;
app.config.globalProperties.Popup = Popup;
app.config.globalProperties.Wait = Wait;
app.config.globalProperties.Ajax = Ajax;
app.config.globalProperties.Exporter = Exporter;
app.config.globalProperties.Animation = Animation;

AppFunction(app.config.globalProperties, (application) => {
  window.$table = SmartTable.init;
  window.$modal = SmartModal.init;

  application.$error = function (page, ex) {
    router.push({ name: "error", params: { page: page, msg: ex.message } });
  };
  application.$go = function (link) {
        if (link) { router.push(link); }
  };
  application.$copy = function (event) {
    if (event) {
      try {
        Web.ClipboardText(event.currentTarget.innerText, false);
      } catch (e) {
        console.error(e);
      }
    }
  };
  application.$copyStyle = function (event) {
    if (event) {
      try {
        Web.ClipboardStyleText(event.currentTarget, false);
      } catch (e) {
        console.error(e);
      }
    }
  };
  application.$copyHtml = function (event) {
    if (event) {
      try {
        Web.ClipboardText(event.currentTarget.innerHTML, false);
      } catch (e) {
        console.error(e);
      }
    }
  };
});

(function () {
  app
    .component('c', Control)
    .component('cf', ControlFilter)
    .component("currency", CurrencyUnit)
    .component("smart-table", SmartTable)
    .component("export-buttons", ExportButtons)
    .component("modal", SmartModal)
    //.component('tabs', Tabs)
    .mount("#app");
})();
