<template>
    <template v-if="isInitialize && !isError">
        <template v-if="validTheme(Enums.THEME.CLASSIC)">
            <LayoutClassic>
                <router-view :key="$route.fullPath" />
            </LayoutClassic>
        </template>
        <template v-if="validTheme(Enums.THEME.CUSTOMER)">
            <LayoutCustomer>
                <router-view :key="$route.fullPath" />
            </LayoutCustomer>
        </template>
        <template v-else-if="validTheme(Enums.THEME.EMPTY)">
            <LayoutEmpty>
                <router-view :key="$route.fullPath" />
            </LayoutEmpty>
        </template>
    </template>
    <template v-else-if="isError">
        <div class="layout-theme emtpy">
            <main class="content-wrapper">
                <ErrorView page="App" :msg="error"></ErrorView>
            </main>
        </div>
    </template>
  <template v-else>
    <PageLoader :isPage="true" :isLoaded="false" />
  </template>
</template>
<script>
    import { reactive, toRefs, computed, watch, onMounted } from "vue";
    import { useRoute } from "vue-router";

    import { Enums, Links } from "./helpers/enums";
    import { appData, C, J } from "./helpers/global";
    import { Web } from "./helpers/web";

    import LayoutClassic from "./components/layout/LayoutClassic.vue";
    import LayoutCustomer from "./components/layout/LayoutCustomer.vue";
    import LayoutEmpty from "./components/layout/LayoutEmpty.vue";
    import ErrorView from "./views/ErrorView.vue";
    import PageLoader from "./components/PageLoader.vue";

    export default {
        name: "App",
        components: {
            LayoutClassic,
            LayoutCustomer,
            LayoutEmpty,
            ErrorView,
            PageLoader,
        },
        setup() {
            //const initialServerBase = window.location.origin;
            const isDevelopment = false;
            const initialServerBase = isDevelopment ? "http://localhost:50001" : "https://demo-api.smartlaw.com.tr";

            const version = '1.01';
            if (version != C.Str(localStorage.getItem('v1'))) {
                localStorage.clear();
                sessionStorage.clear();
                localStorage.setItem('v1', version);
            }
            //const router = useRouter();
            const route = useRoute();
            const e = reactive({
                baseUrl: initialServerBase,
                isInitialize: false,
                error: null,
                page: {
                    css: {},
                    theme: Enums.THEME.NULL,
                    header: {},
                },
                isError: computed(() => {
                    return C.Str(e.error) != "";
                }),
                validTheme: function (theme) {
                    return e.page.theme == theme;
                },
                getInitData: async () => {
                    //e.isInitialize = false;
                    //e.error = null; 
                    fetch(e.baseUrl + "/api/v1/getinfo/init")
                        .then((response) => {
                            if (response.ok) {
                                return response.json();
                            } else {
                                throw new Error(response.statusText);
                            }
                        })
                        .then((r) => {
                            if (C.Int(r.status) == Enums.STATUS.SUCCESS) {
                                try {
                                    appData.initialize(r.data);
                                    //console.log(process.env);
                                    //console.log(appData.base);
                                    e.isInitialize = true;
                                    e.loginDetect();
                                } catch (ex) {
                                    e.error = C.Str(ex.message);
                                }
                            } else {
                                e.error = C.Str(r.message, "There is a problem!");
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            e.error = err.message;
                        });
                },
                loginDetect: function () {
                    if (e.isInitialize) {
                        e.page.theme = C.Int(route.meta.theme);
                        if (!e.validTheme(Enums.THEME.NULL) && !e.validTheme(Enums.THEME.EMPTY)) {
                            // Login Gerekli
                            switch (e.page.theme) {
                                case Enums.THEME.CUSTOMER:
                                    if (C.Str(appData.customer.token) == "" || C.Int(appData.customer.by.Id) <= 0) {
                                        appData.customer.token = "";
                                        appData.customer.by = { Id: 0 };

                                        localStorage.removeItem("CustomerToken");
                                        localStorage.setItem("Customer", J.String(appData.customer.by, { Id: 0 }));

                                        sessionStorage.removeItem("CustomerToken");
                                        sessionStorage.setItem("Customer", J.String(appData.customer.by, { Id: 0 }));
                                        Web.Redirect(Links.Get(Links.Login));
                                    }
                                    break;
                                default:
                                    if (C.Str(appData.user.token) == "" || C.Int(appData.user.by.Id) <= 0) {
                                        appData.user.token = "";
                                        appData.user.by = { Id: 0 };

                                        localStorage.removeItem("UserToken");
                                        localStorage.setItem("User", J.String(appData.user.by, { Id: 0 }));

                                        sessionStorage.removeItem("UserToken");
                                        sessionStorage.setItem("User", J.String(appData.user.by, { Id: 0 }));
                                        Web.Redirect(Links.Get(Links.Login));
                                    }
                                    break;
                            }
                            
                        }
                    }
                },
            });
            onMounted(() => {
                try {
                    e.getInitData();
                } catch (ex) {
                    e.error = ex.message;
                }
            });
            watch(
                route, (newValue, oldValue) => {
                    appData.page = {}; // temp data kullanimi
                    try {
                        e.loginDetect();
                    } catch (ex) {
                        e.error = ex.message;
                    }
                },
                {
                    deep: true,
                    immediate: true,
                }
            );
            return { ...toRefs(e) };
        },
    };
</script>
